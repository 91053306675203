module.exports = [{
      plugin: require('/opt/build/repo/labs/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/labs/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/util/typography"},
    },{
      plugin: require('/opt/build/repo/labs/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"linkImagesToOriginal":false},
    }]
